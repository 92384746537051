<template>
  <router-view/>
</template>

<style>
html, body {
  margin: 0px;
  padding: 0px;
  height: 100%;
  min-height: 100%;
  background-color:#1E1E1E;
  scroll-behavior: smooth;
}


</style>
